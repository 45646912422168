<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{$t('filter')}}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="4"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tenant</label>
          <v-select
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array | Number | String,
      default: null,
    },
  },
}
</script>